@font-face {
  font-family: "LineSeed";
  src: url("./fonts/LINESeedSansTH_Rg.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "LineSeed";
  src: url("./fonts/LINESeedSansTH_Bd.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "LineSeed";
  src: url("./fonts/LINESeedSansTH_XBd.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "LineSeed";
  src: url("./fonts/LINESeedSansTH_He.ttf");
  font-weight: 900;
}
* {
  font-family: "LineSeed";
}

body {
  background-image: url("./bg.jpg");
}

.box-shadow {
  box-shadow: 25px 26px 0px -1px rgba(71, 86, 166, 0.14);
  -webkit-box-shadow: 25px 26px 0px -1px rgba(71, 86, 166, 0.14);
  -moz-box-shadow: 25px 26px 0px -1px rgba(71, 86, 166, 0.14);
}

input::file-selector-button {
  border: none;
  background: #e7e9f3;
  border-radius: 5px;
  cursor: pointer;
}

.detailed-box:hover circle {
  fill: #5266ff;
}
.detailed-box circle {
  fill: #999999;
  transition: all 0.3s;
}
.timeline * {
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.timeline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
/* .timeline::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;

  background-image: url("./img/magnifier.svg");
  background-position: center;
  background-size: 25%;
  background-repeat: no-repeat;
  display: none;

} */
.timeline:hover img {
  scale: 105%;
}
.timeline:hover::before {
  opacity: 1;
}
/* .timeline:hover::after{
  display: block;
} */
